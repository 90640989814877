.loading.cover-content {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.loading.cover-page {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading .ant-spin {
    color: #3e79f7;
}

.d-flex {
    display: flex !important;
    word-wrap: break-word; /* o overflow-wrap: break-word; */
}

/*.timeline-maffle > li {*/
/*    display: flex;*/
/*    align-items: center;*/
/*}*/

/*.timeline-maffle > li > div:nth-child(4) {*/
/*    inset-block-start: -13px !important;*/
/*}*/

.ant-select-selector {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;
    padding-inline: 0;
    border-radius: 10px;
    padding-block: 1px;
    min-height: 42px;
}

.ant-select-selection-item {
    height: 30px;
    line-height: 30px !important;
    margin-left: 4px;
    display: flex;
    align-self: center;
    flex: none;
    box-sizing: border-box;
    max-width: 100%;
    margin-block: 4px;
    border-radius: 6px;
    cursor: default;
    transition: font-size 0.3s, line-height 0.3s, height 0.3s;
    margin-inline-end: 4px;
    padding-inline-start: 8px;
    padding-inline-end: 4px;
}